import { Injectable } from '@angular/core';
import { HotToastService } from '@ngxpert/hot-toast';
import { catchError, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

import { ApiService } from '../api/api.service';
import { ApiResponse } from '../models/ApiResponse.model';
import { StateService } from '../state/state.service';

@Injectable({
  providedIn: 'root',
})
export class WebauthnService {
  constructor(
    private state: StateService,
    private api: ApiService,
    private toast: HotToastService,
  ) { }

  // Registers a new credential (like a fingerprint or Face ID) for the user
  async register() {
    if (!window.PublicKeyCredential) {
      throw new Error('WebAuthn is not supported on this browser');
    }
    const user = this.state.get('user');
    if (!user) {
      throw new Error('User not found');
    }

    // Generate a unique challenge for the registration process
    const res = await lastValueFrom(
      this.api
        .get<
          ApiResponse<{ challenge: Array<number>; challengeId: string }>
        >('/user/webauthn/register/start')
        .pipe(
          catchError((err) => {
            this.toast.error('Failed to get challenge');
            throw err;
          }),
        ),
    );
    const { success, message, challenge, challengeId } = res;
    if (!success) {
      throw new Error(message);
    }
    // PublicKeyCredentialCreationOptions is the core object needed for registration
    const publicKey: PublicKeyCredentialCreationOptions = {
      challenge: new Uint8Array(challenge), // A random value generated by the server to ensure the request is fresh and unique
      rp: {
        name: 'FiniAC', // Display name of your app
      },
      user: {
        // User information
        id: new TextEncoder().encode(user.id), // A unique identifier for the user
        name: user.email, // User's email or username
        displayName: user.username, // A friendly name for the user
      },
      pubKeyCredParams: [
        {
          // Array of acceptable public key algorithms
          type: 'public-key',
          alg: -7, // Represents the ES256 algorithm (Elliptic Curve Digital Signature Algorithm)
        },
        {
          type: 'public-key',
          alg: -257, // Represents the RS256 algorithm (RSA Digital Signature Algorithm)
        }
      ],
      authenticatorSelection: {
        // Criteria for selecting the appropriate authenticator
        authenticatorAttachment: 'platform', // Ensures we use the device's built-in biometric authenticator like Touch ID or Face ID
        userVerification: 'required', // Requires user verification (e.g., fingerprint or face scan)
      },
      timeout: 60000, // Timeout for the registration operation in milliseconds
      attestation: 'direct', // Attestation provides proof of the authenticator's properties and is sent back to the server
    };

    //if (environment.production) {
    //  publicKey.rp.id = 'fini.dev';
    //} else if (window?.location?.hostname === "staging.fini.dev") {
    //  publicKey.rp.id = 'staging.fini.dev';
    //}

    try {
      // This will prompt the user to register their biometric credential
      const credential = (await navigator.credentials.create({
        publicKey,
      })) as PublicKeyCredential;

      // Convert the credential to a format that can be sent to the server
      const credentialForServer = this.credentialToJSON(credential);

      await lastValueFrom(
        this.api.post('/user/webauthn/register/finish', {
          credential: credentialForServer,
          challengeId,
        }),
      );

      return credential; // Return the credential object containing the user's public key and other details
    } catch (err) {
      console.error('Registration failed:', err);
      this.toast.error(`Failed to register. ${err}`);
      throw err; // Handle any errors that occur during registration
    }
  }

  // Authenticates the user with stored credentials (like a fingerprint or Face ID)
  async authenticate(username?: string) {
    if (environment.production) {
      // don't allow authentication in production
      return false;
    }

    if (!window.PublicKeyCredential) {
      throw new Error('WebAuthn is not supported on this browser');
    }

    // Get the challenge and allowed credentials from the server
    const res = await lastValueFrom(
      this.api
        .get<
          ApiResponse<{
            challenge: Array<number>;
            challengeId: string;
            allowCredentials: Array<{
              id: string;
              type: string;
              rawId: string;
            }>;
            getArgs: {
              publicKey: {
                allowCredentials: Array<{
                  id: string;
                  type: string;
                  transports: Array<string>;
                }>;
                challenge: string;
                rpId: string;
                userVerification: string;
              }
            }
          }>
        >(`/user/webauthn/authenticate/start?username=${username}`)
        .pipe(
          catchError((err) => {
            this.toast.error('Failed to get challenge');
            throw err;
          }),
        ),
    );

    const { challenge, challengeId, getArgs: { publicKey: { allowCredentials, rpId } }, allowCredentials: allowCredentialsRaw } = res;
    const allowCredentialsRawFormatted = allowCredentialsRaw.map((cred) => ({
      id: this.base64ToArrayBuffer(cred.rawId),
      type: 'public-key' as const,
    }));
    const allowCredentialsFormatted = allowCredentials.map((cred) => ({
      id: allowedIDToBuffer(cred.id),
      type: 'public-key' as const,
    }));
    function allowedIDToBuffer(id: string) {
      const bytes = new Uint8Array(id.length);
      for (let i = 0; i < id.length; i++) {
        bytes[i] = id.charCodeAt(i);
      }
      return bytes.buffer;
    }
    // Convert the allowed credentials to the format expected by the WebAuthn API
    const allowedCredentialsMixed = [...allowCredentialsFormatted, ...allowCredentialsRawFormatted];

    // PublicKeyCredentialRequestOptions is used to prompt the user to authenticate
    const publicKey: PublicKeyCredentialRequestOptions = {
      challenge: new Uint8Array(challenge), // A new challenge to ensure the request is fresh and unique
      allowCredentials: allowedCredentialsMixed,
      userVerification: 'required', // Requires user verification (e.g., fingerprint or face scan)
      timeout: 60000, // Timeout for the authentication operation in milliseconds
      rpId: rpId,
    };

    try {
      // This will prompt the user to authenticate using their registered biometric credential
      const credential = (await navigator.credentials.get({
        publicKey,
      })) as PublicKeyCredential;

      // Convert the credential to a format that can be sent to the server
      const credentialForServer = this.credentialToJSON(credential);

      const authRes = await lastValueFrom(
        this.api.post('/user/webauthn/authenticate/finish', {
          credential: credentialForServer,
          challengeId,
        }),
      );

      return authRes; // Return the authentication response from the server
    } catch (err) {
      console.error('Authentication failed:', err);
      this.toast.error('Failed to authenticate');
      throw err; // Handle any errors that occur during authentication
    }
  }

  async authenticateSecurityCheck() {
    if (!window.PublicKeyCredential) {
      throw new Error('WebAuthn is not supported on this browser');
    }

    try {
      // Get the challenge from the server
      const res = await lastValueFrom(
        this.api
          .post<
            ApiResponse<{
              challenge: Array<number>;
              challengeId: string;
            }>
          >('/session/security/request', {
            provider: 'passkey',
          })
          .pipe(
            catchError((err) => {
              this.toast.error('Failed to get challenge');
              throw err;
            }),
          ),
      );

      const { challenge } = res;

      // PublicKeyCredentialRequestOptions without allowCredentials
      const publicKey: PublicKeyCredentialRequestOptions = {
        challenge: new Uint8Array(challenge),
        userVerification: 'required',
        timeout: 60000,
      };

      // Use conditional mediation to prompt for available passkeys
      const credential = (await navigator.credentials.get({
        publicKey,
        // mediation: 'conditional', // This enables the browser to suggest passkeys without user input
      })) as PublicKeyCredential;

      // Convert the credential to a format that can be sent to the server
      const credentialForServer = this.credentialToJSON(credential);

      // Send the credential to the passwordless authentication endpoint
      const authRes = await lastValueFrom(
        this.api.post('/session/security', {
          provider: 'passkey',
          code: credentialForServer,
        }),
      );

      return authRes; // Return the authentication response from the server
    } catch (err) {
      console.error('Passwordless authentication failed:', err);
      this.toast.error('Failed to authenticate');
      throw err; // Handle any errors that occur during authentication
    }
  }

  async getMFACodePayload(username: string) {
    try {
      // Get the challenge from the server
      const res = await lastValueFrom(
        this.api
          .get<
            ApiResponse<{
              challenge: Array<number>;
              challengeId: string;
              allowCredentials: Array<{
                id: string;
                type: string;
                rawId: string;
              }>;
              getArgs: {
                publicKey: {
                  allowCredentials: Array<{
                    id: string;
                    type: string;
                    transports: Array<string>;
                  }>;
                  challenge: string;
                  rpId: string;
                  userVerification: string;
                }
              }
            }>
          >('/user/webauthn/authenticate/start', {
            username
          })
          .pipe(
            catchError((err) => {
              this.toast.error('Failed to get challenge');
              throw err;
            }),
          ),
      );

      const { challenge, getArgs: { publicKey: { allowCredentials, rpId } }, allowCredentials: allowCredentialsRaw } = res;
      const allowCredentialsRawFormatted = allowCredentialsRaw.map((cred) => ({
        id: this.base64ToArrayBuffer(cred.rawId),
        type: 'public-key' as const,
      }));
      const allowCredentialsFormatted = allowCredentials.map((cred) => ({
        id: allowedIDToBuffer(cred.id),
        type: 'public-key' as const,
      }));
      function allowedIDToBuffer(id: string) {
        const bytes = new Uint8Array(id.length);
        for (let i = 0; i < id.length; i++) {
          bytes[i] = id.charCodeAt(i);
        }
        return bytes.buffer;
      }
      // Convert the allowed credentials to the format expected by the WebAuthn API
      const allowedCredentialsMixed = [...allowCredentialsFormatted, ...allowCredentialsRawFormatted];

      // PublicKeyCredentialRequestOptions is used to prompt the user to authenticate
      const publicKey: PublicKeyCredentialRequestOptions = {
        challenge: new Uint8Array(challenge), // A new challenge to ensure the request is fresh and unique
        allowCredentials: allowedCredentialsMixed,
        userVerification: 'required', // Requires user verification (e.g., fingerprint or face scan)
        timeout: 60000, // Timeout for the authentication operation in milliseconds
        //rpId: rpId,
      };
      // Use conditional mediation to prompt for available passkeys
      const credential = (await navigator.credentials.get({
        publicKey,
        // mediation: 'conditional', // This enables the browser to suggest passkeys without user input
      })) as PublicKeyCredential;

      // Convert the credential to a format that can be sent to the server
      const credentialForServer = this.credentialToJSON(credential);
      return credentialForServer;
    } catch (err) {
      console.error(`[Passkey] Failed to authenticate passkey. ${err}`);
      this.toast.error('Failed to authenticate passkey');
      return null;
    }
  }

  // Helper method to convert a credential to a JSON object that can be sent to the server
  private credentialToJSON(credential: PublicKeyCredential): any {
    const response = credential.response as
      | AuthenticatorAttestationResponse
      | AuthenticatorAssertionResponse;

    // Base data that both attestation and assertion responses have
    const result: any = {
      id: credential.id,
      rawId: this.arrayBufferToBase64(credential.rawId),
      type: credential.type,
      response: {},
    };

    // Add response data based on the type of response
    if ('attestationObject' in response) {
      // This is a registration response
      result.response.attestationObject = this.arrayBufferToBase64(
        response.attestationObject,
      );
      result.response.clientDataJSON = this.arrayBufferToBase64(
        response.clientDataJSON,
      );
    } else {
      // This is an authentication response
      result.response.authenticatorData = this.arrayBufferToBase64(
        response.authenticatorData,
      );
      result.response.clientDataJSON = this.arrayBufferToBase64(
        response.clientDataJSON,
      );
      result.response.signature = this.arrayBufferToBase64(response.signature);
      if (response.userHandle) {
        result.response.userHandle = this.arrayBufferToBase64(
          response.userHandle,
        );
      }
    }

    return result;
  }

  // Helper method to convert an ArrayBuffer to a Base64 string
  private arrayBufferToBase64(buffer: ArrayBuffer): string {
    const bytes = new Uint8Array(buffer);
    let binary = '';
    for (let i = 0; i < bytes.byteLength; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  // Helper method to convert a Base64 string to an ArrayBuffer
  private base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const bytes = new Uint8Array(binaryString.length);
    for (let i = 0; i < binaryString.length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }

  // Get all passkeys for the current user
  async listPasskeys() {
    try {
      const res = await lastValueFrom(
        this.api.get<
          ApiResponse<{
            success: boolean;
            passkeys: Array<{
              id: string;
              description: string;
              time_added: string;
              time_updated: string;
            }>;
          }>
        >('/user/webauthn/passkeys'),
      );
      return res;
    } catch (err) {
      console.error('Failed to fetch passkeys:', err);
      throw err;
    }
  }

  // Delete a specific passkey by ID
  async deletePasskey(passkeyId: string) {
    try {
      const res = await lastValueFrom(
        this.api.post<ApiResponse<{ success: boolean }>>(
          '/user/webauthn/passkeys/delete',
          {
            passkey_id: passkeyId,
          },
        ),
      );
      return res;
    } catch (err) {
      console.error('Failed to delete passkey:', err);
      this.toast.error('Failed to delete passkey');
      throw err;
    }
  }
}
